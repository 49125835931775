import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';

const ClearCachePage = () => {
  const [showModal, setShowModal] = useState(false); // State to control the modal
  const navigate = useNavigate();

  const handleClearCache = () => {
    try {
      // Clear localStorage
      localStorage.clear();
      // Clear sessionStorage
      sessionStorage.clear();
      // Optionally, clear other caches like service worker
      caches.keys().then((cacheNames) => {
        cacheNames.forEach((cacheName) => caches.delete(cacheName));
      });

      alert('All cache has been cleared!');
      navigate('/'); // Redirect to home or any other page after clearing
    } catch (error) {
      console.error('Error clearing cache:', error);
      alert('An error occurred while clearing the cache.');
    } finally {
      setShowModal(false); // Close the modal
    }
  };

  return (
    <div style={{ textAlign: 'center', padding: '2em' }}>
      <h1>Clear Cache</h1>
      <p>Click the button below to clear all site caches.</p>
      <Button
        variant="danger"
        size="lg"
        onClick={() => setShowModal(true)} // Show the modal on click
      >
        Clear Cache
      </Button>

      {/* Modal */}
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header>
          <Modal.Title>Clear Cache</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to clear all cache? This action cannot be undone.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleClearCache}>
            Clear Cache
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ClearCachePage;
