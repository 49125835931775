import React from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  PieChart,
  Pie,
  Legend,
  ResponsiveContainer,
  LineChart,
  Line,
  CartesianGrid,
  Cell
} from 'recharts';

const NoDataMessage = () => (
  <div style={{ textAlign: 'center', paddingTop: '50px', color: '#999' }}>
    <strong>No Data</strong>
  </div>
);

const CampusTaskGraphs = ({
  tasksByStatusData,
  tasksByPriorityData,
  tasksByLocationData,
  tasksByDepartmentData,
  tasksOverTimeData,
  dateFormatMode,
  tasksOverdueByStatusData,
  tasksPerCampusData  // New data for the per-campus status overview
}) => {

  const formatDateTick = (dateStr) => {
    const date = new Date(dateStr);
    const options = {};
    if (dateFormatMode === 'dd') {
      options.day = '2-digit';
    } else if (dateFormatMode === 'MMM') {
      options.month = 'short';
    } else {
      options.month = 'short';
      options.year = 'numeric';
    }
    return date.toLocaleDateString(undefined, options);
  };

  const departmentChartWidth = Math.max(tasksByDepartmentData.length * 10, 800);

  // In the image provided: "Facilities Task Overview" includes statuses:
  // Task Created, New Task, Task Seen, On It, Done It, On Hold, Task Overdue
  // We assume tasksPerCampusData has these fields.

  return (
    <div className="mb-4">
      <h2>Campus Graphs</h2>
      <Row className="mb-4">
        <Col md={6}>
          <Card>
            <Card.Header>Tasks by Status</Card.Header>
            <Card.Body style={{ height: '300px' }}>
              {tasksByStatusData.length === 0 ? <NoDataMessage /> : (
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart data={tasksByStatusData}>
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="count">
                      {tasksByStatusData.map((entry, index) => (
                        <Cell key={`cell-status-${index}`} fill={entry.color} />
                      ))}
                    </Bar>
                  </BarChart>
                </ResponsiveContainer>
              )}
            </Card.Body>
          </Card>
        </Col>

        <Col md={6}>
          <Card>
            <Card.Header>Overdue Tasks by Status (0,1,2)</Card.Header>
            <Card.Body style={{ height: '300px' }}>
              {tasksOverdueByStatusData.length === 0 ? <NoDataMessage /> : (
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart data={tasksOverdueByStatusData}>
                    <CartesianGrid stroke="#ccc" strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="value" barSize={20}>
                      {tasksOverdueByStatusData.map((entry, index) => (
                        <Cell key={`cell-ovstatus-${index}`} fill={entry.color} />
                      ))}
                    </Bar>
                  </BarChart>
                </ResponsiveContainer>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      
      <Row className="mb-4">
        <Col md={6}>
          <Card>
            <Card.Header>Tasks by Priority</Card.Header>
            <Card.Body style={{ height: '300px' }}>
              {tasksByPriorityData.length === 0 ? <NoDataMessage /> : (
                <ResponsiveContainer width="100%" height="100%">
                  <PieChart>
                    <Pie
                      data={tasksByPriorityData}
                      dataKey="value"
                      nameKey="name"
                      cx="50%"
                      cy="50%"
                      outerRadius={80}
                      label
                    >
                      {tasksByPriorityData.map((entry, index) => (
                        <Cell key={`cell-priority-${index}`} fill={entry.color} />
                      ))}
                    </Pie>
                    <Tooltip />
                    <Legend />
                  </PieChart>
                </ResponsiveContainer>
              )}
            </Card.Body>
          </Card>
        </Col>

        <Col md={6}>
          <Card>
            <Card.Header>Tasks by Location (Campuses)</Card.Header>
            <Card.Body style={{ height: '300px' }}>
              {tasksByLocationData.length === 0 ? <NoDataMessage /> : (
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart data={tasksByLocationData}>
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="count">
                      {tasksByLocationData.map((entry, index) => (
                        <Cell key={`cell-loc-${index}`} fill={entry.color} />
                      ))}
                    </Bar>
                  </BarChart>
                </ResponsiveContainer>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row className="mb-4">
        <Col md={12}>
          <Card>
            <Card.Header>Tasks by Department</Card.Header>
            <Card.Body style={{ height: '400px', overflowX: 'auto' }}>
              {tasksByDepartmentData.length === 0 ? (
                <NoDataMessage />
              ) : (
                <div style={{ width: '100%', height: '100%' }}>
                  <ResponsiveContainer width="100%" height="100%">
                    <BarChart data={tasksByDepartmentData} margin={{ top: 20, bottom: 20, left: 20, right: 20 }}>
                      <CartesianGrid stroke="#ccc" strokeDasharray="3 3" />
                      <XAxis 
                        dataKey="name" 
                        interval={0}
                        tick={{ angle: -30, textAnchor: 'end' }}
                        height={80}
                      />
                      <YAxis />
                      <Tooltip />
                      <Bar dataKey="value" barSize={4} fill="#82ca9d" />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* New Chart: Per Campus Status Overview */}
      <Row className="mb-4">
        <Col md={12}>
          <Card>
            <Card.Header>Per Campus Status Overview</Card.Header>
            <Card.Body style={{ height: '300px' }}>
              {(!tasksPerCampusData || tasksPerCampusData.length === 0) ? (
                <NoDataMessage />
              ) : (
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart data={tasksPerCampusData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="campus" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    {/* Bars for each status as in the provided image */}
                    <Bar dataKey="Task Created" fill="#1f4e79" name="Task Created" />
                    <Bar dataKey="New Task" fill="#FFFF00" name="New Task" />
                    <Bar dataKey="Task Seen" fill="#c71585" name="Task Seen" />
                    <Bar dataKey="On It" fill="#40e0d0" name="On It" />
                    <Bar dataKey="Done It" fill="#FFD700" name="Done It" />
                    <Bar dataKey="On Hold" fill="#008000" name="On Hold" />
                    <Bar dataKey="Task Overdue" fill="#FF0000" name="Task Overdue" />
                  </BarChart>
                </ResponsiveContainer>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col md={12}>
          <Card>
            <Card.Header>Tasks Over Time</Card.Header>
            <Card.Body style={{ height: '300px' }}>
              {tasksOverTimeData.length === 0 ? <NoDataMessage /> : (
                <ResponsiveContainer width="100%" height="100%">
                  <LineChart data={tasksOverTimeData}>
                    <CartesianGrid stroke="#ccc" />
                    <XAxis dataKey="date" tickFormatter={formatDateTick} 
                        tick={{ angle: -30, textAnchor: 'end' }}
                        height={60} />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line type="monotone" dataKey="count" stroke="#ff7300" />
                  </LineChart>
                </ResponsiveContainer>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default CampusTaskGraphs;
