import React, { useState, useEffect, Fragment } from 'react';
import { Button, Form, Row, Col, Card } from 'react-bootstrap';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';

import Shimmer from '../../../../../components/Custom/Loading/Shimmer';
import CampusTaskTable from './CampusTaskTable';
import CampusTaskStats from './CampusTaskStats';
import CampusTaskGraphs from './CampusTaskGraphs';

import {
  TaskReports,
  GetAssignedUser,
} from '../../../../../../services/ccms/taskManagement/tasks/tasks_endpoints';
import { TaskStatusList } from '../../../../../../services/ccms/taskManagement/taskStatus/task_status_endpoints';
import { TaskPriorityList } from '../../../../../../services/ccms/taskManagement/taskPriority/task_priority_endpoints';
import { LocationList } from '../../../../../../services/ccms/locationManagement/location/location_endpoints';
import { DepartmentList } from '../../../../../../services/ccms/departmentManagement/department/department_endpoints';

const today = new Date();
const pastDate = new Date(today);
pastDate.setDate(today.getDate() - 30);
const futureDate = new Date(today);
futureDate.setDate(today.getDate() + 1);

const defaultFilters = {
  search: 'NULL',
  taskID: 'NULL',
  userID: 'NULL',
  startDate: pastDate.toISOString().split('T')[0],
  endDate: futureDate.toISOString().split('T')[0],
  priorityID: 'NULL',
  statusID: 'NULL',
  locationID: 'NULL',  // Campus filter
  departmentID: 'NULL',
  assignedBy: 'NULL',
  createdBy: 'NULL',
};

const parseCustomDateTime = (dateStr) => {
  if (!dateStr || dateStr === 'NULL' || dateStr.length !== 14) return null;
  const year = parseInt(dateStr.slice(0,4), 10);
  const month = parseInt(dateStr.slice(4,6), 10);
  const day = parseInt(dateStr.slice(6,8), 10);
  const hour = parseInt(dateStr.slice(8,10), 10);
  const minute = parseInt(dateStr.slice(10,12), 10);
  const second = parseInt(dateStr.slice(12,14), 10);

  if ([year,month,day,hour,minute,second].some(isNaN)) return null;
  return new Date(year, month - 1, day, hour, minute, second);
};

const colorPalette = ['#8884d8', '#82ca9d', '#FFBB28', '#FF8042', '#00C49F', '#FFBB28', '#0088FE'];

const getColorFromModel = (modelObj, fallbackIndex=0) => {
  if (modelObj && modelObj.color) return modelObj.color;
  if (modelObj && modelObj.Color) return modelObj.Color;
  return colorPalette[fallbackIndex % colorPalette.length];
};

const isOverdue = (task) => {
  if (!task.dueDate) return false;
  const due = new Date(task.dueDate);
  const now = new Date();
  const incompleteStatuses = [-2, -1, 0, 1, 2];
  return due < now && incompleteStatuses.includes(task.statusID);
};

const getTaskDateForTimeline = (task) => {
  const created = parseCustomDateTime(task.createdDateTime);
  const modified = parseCustomDateTime(task.modifiedDateTime);

  if (created) return created;
  if (!created && modified) return modified;
  if (!created && !modified && task.dueDate && task.dueDate !== 'NULL') {
    return new Date(task.dueDate);
  }
  return null;
};

const LocationReportsPage = () => {
  const [filters, setFilters] = useState(defaultFilters);
  const [overdueFilter, setOverdueFilter] = useState(false);
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(false);

  const [statusList, setStatusList] = useState([]);
  const [priorityList, setPriorityList] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);

  const [stats, setStats] = useState({});
  const [tasksByStatusData, setTasksByStatusData] = useState([]);
  const [tasksByPriorityData, setTasksByPriorityData] = useState([]);
  const [tasksByLocationData, setTasksByLocationData] = useState([]);
  const [tasksByDepartmentData, setTasksByDepartmentData] = useState([]);
  const [tasksOverTimeData, setTasksOverTimeData] = useState([]);
  const [dateFormatMode, setDateFormatMode] = useState('');
  const [tasksOverdueByStatusData, setTasksOverdueByStatusData] = useState([]);

  // New: tasksPerCampusData for the per-campus status overview chart
  const [tasksPerCampusData, setTasksPerCampusData] = useState([]);

  useEffect(() => {
    fetchInitialData();
  }, []);

  const fetchInitialData = async () => {
    try {
      const [statuses, priorities, locations, departments] = await Promise.all([
        TaskStatusList(),
        TaskPriorityList(),
        LocationList(),
        DepartmentList(),
      ]);

      setStatusList(statuses.results);
      setPriorityList(priorities.results);
      setLocationList(locations.results);
      setDepartmentList(departments.results);
    } catch (error) {
      console.error('Error fetching initial data:', error);
    }
  };

  useEffect(() => {
    if (
      statusList.length > 0 &&
      priorityList.length > 0 &&
      locationList.length > 0 &&
      departmentList.length > 0
    ) {
      fetchTaskReports();
    }
  }, [statusList, priorityList, locationList, departmentList]);

  const fetchTaskReports = async () => {
    setLoading(true);
    try {
      const taskReports = await TaskReports(filters);
      const tasksWithUsers = await Promise.all(
        taskReports.results.map(async (task) => {
          let assignedUsers = [];
          if (task.taskAssignedUsers && task.taskAssignedUsers.length > 0) {
            assignedUsers = task.taskAssignedUsers;
          } else {
            const assignedUsersList = await GetAssignedUser(task.taskID);
            assignedUsers = assignedUsersList.results;
          }
          return { ...task, assignedUsers };
        })
      );
      setTasks(tasksWithUsers);
      prepareData(tasksWithUsers);
    } catch (error) {
      console.error('Error fetching campus task reports:', error);
    } finally {
      setLoading(false);
    }
  };

  const prepareData = (tasksArr) => {
    const totalTasks = tasksArr.length;
    const overdueCount = tasksArr.filter(isOverdue).length;
    const completedCount = tasksArr.filter(t => t.statusID === 3 || t.statusID === 4).length; 
    const onHoldCount = tasksArr.filter(t => t.statusID === -1).length;
    const reopenedCount = tasksArr.filter(t => t.statusID === -2).length;

    const highPriorityCount = tasksArr.filter(t => t.priorityID === 2).length;  
    const mediumPriorityCount = tasksArr.filter(t => t.priorityID === 1).length; 
    const lowPriorityCount = tasksArr.filter(t => t.priorityID === 0).length;

    // STATUS DATA
    const statusCounts = {};
    statusList.forEach((s,i) => statusCounts[s.statusID] = {count:0, index:i});
    tasksArr.forEach(t => {
      if (statusCounts[t.statusID]) {
        statusCounts[t.statusID].count++;
      }
    });
    const tasksByStatus = Object.keys(statusCounts)
      .map(sID => {
        const sObj = statusList.find(s => s.statusID === parseInt(sID));
        const {count, index} = statusCounts[sID];
        return {
          name: sObj ? sObj.name : `Status ${sID}`,
          count,
          color: getColorFromModel(sObj, index)
        };
      })
      .filter(item => item.count > 0);

    // PRIORITY DATA
    const priorityCounts = {0:0,1:0,2:0};
    tasksArr.forEach(t => {
      if (priorityCounts[t.priorityID] !== undefined) {
        priorityCounts[t.priorityID]++;
      }
    });
    const tasksByPriority = Object.keys(priorityCounts)
      .map((pID,i) => {
        const pObj = priorityList.find(p => p.priorityID === parseInt(pID));
        return {
          name: pObj ? pObj.name : `Priority ${pID}`,
          value: priorityCounts[pID],
          color: getColorFromModel(pObj, i)
        };
      })
      .filter(item => item.value > 0);

    // LOCATION DATA (Campuses)
    const locationCounts = {};
    locationList.forEach((l,i) => locationCounts[l.locationID] = {count:0, index:i});
    tasksArr.forEach(t => {
      if (locationCounts[t.locationID]) {
        locationCounts[t.locationID].count++;
      }
    });
    const tasksByLocation = Object.keys(locationCounts)
      .map(lID => {
        const lObj = locationList.find(l => l.locationID === parseInt(lID));
        const {count, index} = locationCounts[lID];
        return {
          name: lObj ? lObj.name : `Campus ${lID}`,
          count,
          color: getColorFromModel(lObj, index)
        };
      })
      .filter(item => item.count > 0);

    // DEPARTMENT DATA
    const departmentCounts = {};
    departmentList.forEach((d,i) => departmentCounts[d.departmentID] = {count:0, index:i});
    tasksArr.forEach(t => {
      if (departmentCounts[t.departmentID]) {
        departmentCounts[t.departmentID].count++;
      }
    });
    const tasksByDepartment = Object.keys(departmentCounts)
      .map(dID => {
        const dObj = departmentList.find(d => d.departmentID === parseInt(dID));
        const {count, index} = departmentCounts[dID];
        return {
          name: dObj ? dObj.name : `Department ${dID}`,
          value: count,
          color: getColorFromModel(dObj, index)
        };
      })
      .filter(item => item.value > 0);

    // TASKS OVER TIME
    const dateMap = {};
    tasksArr.forEach(t => {
      const dateObj = getTaskDateForTimeline(t);
      if (dateObj) {
        const dateStr = dateObj.toISOString().slice(0,10);
        if (!dateMap[dateStr]) dateMap[dateStr] = 0;
        dateMap[dateStr]++;
      }
    });
    const overTimeData = Object.keys(dateMap)
      .map(d => ({ date: d, count: dateMap[d] }))
      .sort((a,b) => new Date(a.date) - new Date(b.date));

    let dateFormatMode = 'MMM yyyy'; 
    if (overTimeData.length > 0) {
      const dates = overTimeData.map(i => new Date(i.date));
      const minDate = new Date(Math.min(...dates));
      const maxDate = new Date(Math.max(...dates));

      const sameYear = minDate.getFullYear() === maxDate.getFullYear();
      const sameMonth = sameYear && (minDate.getMonth() === maxDate.getMonth());

      if (sameMonth) {
        dateFormatMode = 'dd';
      } else if (sameYear) {
        dateFormatMode = 'MMM';
      } else {
        dateFormatMode = 'MMM yyyy';
      }
    }

    // Overdue tasks per status (0,1,2)
    const overdueStatuses = [0,1,2];
    const overdueTasks = tasksArr.filter(t => isOverdue(t) && overdueStatuses.includes(t.statusID));
    const overdueStatusCounts = {};
    overdueStatuses.forEach(sId => {
      overdueStatusCounts[sId] = 0;
    });
    overdueTasks.forEach(t => {
      overdueStatusCounts[t.statusID]++;
    });
    const tasksOverdueByStatus = Object.keys(overdueStatusCounts).map((sID,i) => {
      const sObj = statusList.find(s => s.statusID === parseInt(sID));
      return {
        name: sObj ? sObj.name : `Status ${sID}`,
        value: overdueStatusCounts[sID],
        color: getColorFromModel(sObj, i)
      };
    }).filter(item => item.value > 0);

    // Compute star rating for the campus:
    let completionRate = 0;
    if (totalTasks > 0) {
      completionRate = (completedCount / totalTasks) * 100;
    }
    let stars = 1;
    if (completionRate > 20) stars = 2;
    if (completionRate > 40) stars = 3;
    if (completionRate > 60) stars = 4;
    if (completionRate > 80) stars = 5;
    const starString = '★'.repeat(stars) + '☆'.repeat(5 - stars);

    setDateFormatMode(dateFormatMode);

    setStats({
      totalTasks,
      overdueCount,
      completedCount,
      onHoldCount,
      reopenedCount,
      highPriorityCount,
      mediumPriorityCount,
      lowPriorityCount,
      completionRate,
      starRating: starString
    });
    setTasksByStatusData(tasksByStatus);
    setTasksByPriorityData(tasksByPriority);
    setTasksByLocationData(tasksByLocation);
    setTasksByDepartmentData(tasksByDepartment);
    setTasksOverTimeData(overTimeData);
    setTasksOverdueByStatusData(tasksOverdueByStatus);

    // Prepare data for per-campus status overview chart:
    // We want a record per campus with: 
    // Task Created (total tasks), New Task(0), Task Seen(1), On It(2), Done It(3,4 combined), On Hold(-1), Task Overdue
    const campusMap = {}; 
    // Initialize campusMap for each location we have tasks for
    tasksArr.forEach(t => {
      if (!campusMap[t.locationID]) {
        const lObj = locationList.find(l => l.locationID === t.locationID);
        campusMap[t.locationID] = {
          campus: lObj ? lObj.name : `Campus ${t.locationID}`,
          TaskCreated: 0,
          "New Task": 0,
          "Task Seen": 0,
          "On It": 0,
          "Done It": 0,
          "On Hold": 0,
          "Task Overdue": 0
        };
      }
      campusMap[t.locationID].TaskCreated++;
      if (t.statusID === 0) campusMap[t.locationID]["New Task"]++;
      if (t.statusID === 1) campusMap[t.locationID]["Task Seen"]++;
      if (t.statusID === 2) campusMap[t.locationID]["On It"]++;
      // Done It (3) and Closed(4) are combined into "Done It"
      if (t.statusID === 3 || t.statusID === 4) campusMap[t.locationID]["Done It"]++;
      if (t.statusID === -1) campusMap[t.locationID]["On Hold"]++;
      if (isOverdue(t)) campusMap[t.locationID]["Task Overdue"]++;
    });

    const tasksPerCampus = Object.keys(campusMap).map(lID => campusMap[lID]);
    setTasksPerCampusData(tasksPerCampus);
  };

  const handleFilterChange = (field, value) => {
    setFilters((prev) => ({ ...prev, [field]: value === '' ? 'NULL' : value }));
  };

  const applyFilters = () => {
    fetchTaskReports();
  };

  return (
    <Fragment>
      <div className="container-fluid">
        <h1 className="mb-4">Campus Task Reports</h1>

        <Card className="mb-3">
          <Card.Body>
            <Row>
              <Col md={3}>
                <Form.Group>
                  <Form.Label>Search (Title/Desc)</Form.Label>
                  <Form.Control
                    type="text"
                    size="sm"
                    onChange={(e) => handleFilterChange('search', e.target.value || 'NULL')}
                    placeholder="e.g. 'Sunday Graphics'"
                  />
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group>
                  <Form.Label>Campus</Form.Label>
                  <Form.Select size="sm" onChange={(e) => handleFilterChange('locationID', e.target.value || 'NULL')}>
                    <option value="">All</option>
                    {locationList.map(l => (
                      <option key={l.locationID} value={l.locationID}>{l.name}</option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Label>Date Range (Created Date)</Form.Label>
                <DateRangePicker
                  size="sm"
                  onApply={(event, picker) => {
                    handleFilterChange('startDate', picker.startDate.format('YYYY-MM-DD'));
                    handleFilterChange('endDate', picker.endDate.format('YYYY-MM-DD'));
                  }}
                >
                                    <Form.Control type="text" size="sm" className="input-daterange-timepicker" />
                </DateRangePicker>
              </Col>
              <Col md={2} className="d-flex align-items-end mt-2 mt-md-0">
                <Form.Check
                  type="checkbox"
                  size="sm"
                  label="Only Overdue"
                  checked={overdueFilter}
                  onChange={(e) => setOverdueFilter(e.target.checked)}
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={3}>
                <Form.Group>
                  <Form.Label>Priority</Form.Label>
                  <Form.Select size="sm" onChange={(e) => handleFilterChange('priorityID', e.target.value || 'NULL')}>
                    <option value="">All</option>
                    {priorityList.map(p => (
                      <option key={p.priorityID} value={p.priorityID}>{p.name}</option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group>
                  <Form.Label>Status</Form.Label>
                  <Form.Select size="sm" onChange={(e) => handleFilterChange('statusID', e.target.value || 'NULL')}>
                    <option value="">All</option>
                    {statusList.map(s => (
                      <option key={s.statusID} value={s.statusID}>{s.name}</option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group>
                  <Form.Label>Department</Form.Label>
                  <Form.Select size="sm" onChange={(e) => handleFilterChange('departmentID', e.target.value || 'NULL')}>
                    <option value="">All</option>
                    {departmentList.map(d => (
                      <option key={d.departmentID} value={d.departmentID}>{d.name}</option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md={2} className="d-flex align-items-end">
                <Button variant="primary" onClick={applyFilters}>
                  Apply Filters
                </Button>
              </Col>
            </Row>
          </Card.Body>
        </Card>

        {/* <div className="mb-3 d-flex">
          <Button variant="outline-secondary" className="me-2">Export to PDF</Button>
          <Button variant="outline-secondary" className="me-2">Export to Excel</Button>
        </div> */}

        {loading ? (
          <Shimmer type="article" />
        ) : (
          <>
          <CampusTaskTable
            tasks={tasks}
            loading={loading}
            overdueFilter={overdueFilter}
            isOverdue={isOverdue}
          />

        <CampusTaskStats stats={stats} />

        <CampusTaskGraphs
          tasksByStatusData={tasksByStatusData}
          tasksByPriorityData={tasksByPriorityData}
          tasksByLocationData={tasksByLocationData}
          tasksByDepartmentData={tasksByDepartmentData}
          tasksOverTimeData={tasksOverTimeData}
          dateFormatMode={dateFormatMode}
          tasksOverdueByStatusData={tasksOverdueByStatusData}
          // Pass tasksPerCampusData for the new grouped bar chart
          tasksPerCampusData={tasksPerCampusData}
        />

</>
        )}
      </div>
    </Fragment>
  );
};

export default LocationReportsPage;
