import React, { useState, useEffect, Fragment } from 'react';
import { Form, Button, Row, Col, Modal, Card } from 'react-bootstrap';
import { getUserDetails, updateUserDetails, changeAvatar } from '../../../../services/ccms/userManagement/user/user_endpoints'; 
import { changePassword } from '../../../../services/ccms/userManagement/account/account_endpoints';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import profileImg from '../../../../images/empty_user.jpg'; 
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import Shimmer from '../../../components/Custom/Loading/Shimmer'; 
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const MySwal = withReactContent(Swal);

const Profile = () => {
  const [userDetails, setUserDetails] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [croppedImage, setCroppedImage] = useState(null);
  const [showCropperModal, setShowCropperModal] = useState(false);
  const [cropper, setCropper] = useState(null);
  const [src, setSrc] = useState(null);
  const [formData, setFormData] = useState({
    userId: '',
    firstName: '',
    surname: '',
    email: '',
    avatar: '',
    dateCreated: '',
    modifiedDateTime: '',
    createdDateTime: '',
    file: null,
  });
  const [changePasswordForm, setChangePasswordForm] = useState({
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
  });
  const userSystemSettings = JSON.parse(localStorage.getItem('userDetails'));

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await getUserDetails(userSystemSettings.userId);
        setUserDetails(response.results);
        setFormData({
          userId: response.results.userId,
          firstName: response.results.firstName,
          surname: response.results.surname,
          email: response.results.email,
          avatar: response.results.avatar || '',
          dateCreated: response.results.dateCreated,
          modifiedDateTime: response.results.modifiedDateTime,
          createdDateTime: response.results.createdDateTime
        });
        setIsLoading(false);
      } catch (error) {
        console.error(error);
        toast.error('Failed to load user details');
        setIsLoading(false);
      }
    };

    fetchUserDetails();
  }, [userSystemSettings.userId]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setShowCropperModal(true);
      setSrc(URL.createObjectURL(file)); 
      setFormData({ ...formData, file });
    }
  };


  const handlePasswordChange = async () => {
    setIsSaving(true);


    try {
      //validate password if it meets the requirements
      if (changePasswordForm.newPassword !== changePasswordForm.confirmPassword) {
        toast.error('Passwords do not match');
        MySwal.fire({
          title: 'Error',
          text: 'Passwords do not match',
          icon: 'error',
        });
        setIsSaving(false);
        return;
      }

      // Check if the new password is the same as the current password
      if (changePasswordForm.currentPassword === changePasswordForm.newPassword) {
        toast.error('New password cannot be the same as the current password');
        MySwal.fire({
          title: 'Error',
          text: 'New password cannot be the same as the current password',
          icon: 'error',
        });
        setIsSaving(false);
        return;
      }

      // Check if password is 8 characters or more, contains at least one letter, one number and one special character
      const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
      if (!passwordRegex.test(changePasswordForm.newPassword)) {
        toast.error('Password must be 8 characters or more, contain at least one letter, one number and one special character');
        MySwal.fire({
          title: 'Error',
          text: 'Password must be 8 characters or more, contain at least one letter, one number and one special character',
          icon: 'error',
        });
        setIsSaving(false);
        return;
      }

      const result = await MySwal.fire({
        title: 'Are you sure?',
        text: 'You are about to change your password. Do you want to proceed?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, change password',
        cancelButtonText: 'No, cancel',
      });

      if (!result.isConfirmed) {
        return;
      }

      const response = await changePassword(changePasswordForm);
      MySwal.fire({
        title: 'Success',
        text: response.message,
        icon: 'success',
      });
      setIsSaving(false);
    } catch (error) {
      console.error(error);
      MySwal.fire({
        title: 'Error',
        text: error.message,
        icon: 'error',
      });
      setIsSaving(false);
    }
  };  

  const handleCrop = () => {
    if (typeof cropper.getCroppedCanvas() === 'undefined') {
      return;
    }

    const croppedCanvas = cropper.getCroppedCanvas({
      width: 250,
      height: 250,
      imageSmoothingQuality: 'medium',
    });

    setCroppedImage(croppedCanvas.toDataURL());
    setShowCropperModal(false);
  };

  const handleAvatarChange = async () => {
    setIsSaving(true);

    try {
      const blob = await (await fetch(croppedImage)).blob();
      const file = new File([blob], formData.file.name, { type: formData.file.type });

      const formDataToSend = new FormData();
      formDataToSend.append('file', file);

      const response = await changeAvatar(userSystemSettings.userId, formDataToSend);
      setFormData({ ...formData, avatar: response.results.avatar });
      
      const updatedUserDetails = { ...userSystemSettings, avatar: response.results.avatar };
      localStorage.setItem('userDetails', JSON.stringify(updatedUserDetails));

      toast.success('Avatar updated successfully!');
      setCroppedImage(null); 
      setIsSaving(false);
    } catch (error) {
      console.error(error);
      toast.error('Failed to update avatar');
      setIsSaving(false);
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setIsSaving(true);

    const formDataToSubmit = new FormData();
    formDataToSubmit.append('userId', formData.userId);
    formDataToSubmit.append('firstName', formData.firstName);
    formDataToSubmit.append('surname', formData.surname);
    formDataToSubmit.append('email', formData.email);
    formDataToSubmit.append('avatar', formData.avatar);
    formDataToSubmit.append('dateCreated', formData.dateCreated);
    formDataToSubmit.append('modifiedDateTime', formData.modifiedDateTime);
    formDataToSubmit.append('createdDateTime', formData.createdDateTime);

    if (formData.file) {
      formDataToSubmit.append('file', formData.file);
    }

    try {
      const response = await updateUserDetails(formDataToSubmit);
      toast.success('User details updated successfully');
      
      const updatedUserDetails = {
        ...userSystemSettings,
        firstName: response.results.firstName,
        surname: response.results.surname,
        email: response.results.email,
        avatar: response.results.avatar,
      };
      localStorage.setItem('userDetails', JSON.stringify(updatedUserDetails));
      
      setIsSaving(false);
    } catch (error) {
      toast.error('Failed to update user details');
      setIsSaving(false);
    }
  };

  if (isLoading) {
    return (
      <Shimmer type="page" />
    );
  }

  return (
    <Fragment>
      <ToastContainer />
      <Card>
        <Card.Body>
          <div className="text-center mb-4">
            <div className="position-relative d-inline-block">
              <img
                src={croppedImage || formData.avatar || profileImg}
                alt="Profile"
                className="rounded-circle"
                style={{ width: '150px', height: '150px', objectFit: 'cover' }}
              />
              <div
                className="position-absolute bottom-0 end-0 bg-black p-2 rounded-circle"
                style={{ cursor: 'pointer', backgroundColor: '#ffffff', border: '2px solid white' }}
                onClick={() => document.getElementById('fileInput').click()}
              >
                <FontAwesomeIcon icon={faSyncAlt} size="lg" color='white' />
              </div>
              {croppedImage && (
                <div>
                  <Button size='xxs' onClick={handleAvatarChange} disabled={isSaving}>Save Avatar</Button>
                </div>
              )}
            </div>
            <input
              type="file"
              id="fileInput"
              style={{ display: 'none' }}
              onChange={handleFileChange}
            />
          </div>
          <Form onSubmit={handleFormSubmit}>
            <Row>
              <Col md={6}>
                <Form.Group controlId="formFirstName" className="mb-3">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="firstName"
                    value={formData.firstName}
                    onChange={(e) => setFormData({ ...formData, firstName: e.target.value })}
                    required
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="formSurname" className="mb-3">
                  <Form.Label>Surname</Form.Label>
                  <Form.Control
                    type="text"
                    name="surname"
                    value={formData.surname}
                    onChange={(e) => setFormData({ ...formData, surname: e.target.value })}
                    required
                  />
                </Form.Group>
              </Col>
            </Row>
            <Form.Group controlId="formEmail" className="mb-3">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={formData.email}
                onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                required
              />
            </Form.Group>
            <Button variant="primary" type="submit" disabled={isSaving}>
              {isSaving ? 'Saving...' : 'Save Changes'}
            </Button>
          </Form>
        </Card.Body>
      </Card>

{/* Change password card */}
<Card className='shadow-sm'>
  <Card.Body>
    <h5 className='mb-4'>Change Password</h5>
    <Form>
      <Form.Group controlId="formCurrentPassword" className="mb-3">
        <Form.Label>Current Password</Form.Label>
        <Form.Control
          type="password"
          name="currentPassword"
          value={changePasswordForm.currentPassword}
          onChange={(e) => setChangePasswordForm({ ...changePasswordForm, oldPassword: e.target.value })}
          required
        />
      </Form.Group>
      <Form.Group controlId="formNewPassword" className="mb-3">
        <Form.Label>New Password</Form.Label>
        <Form.Control
          type="password"
          name="newPassword"
          value={changePasswordForm.newPassword}
          onChange={(e) => setChangePasswordForm({ ...changePasswordForm, newPassword: e.target.value })}
          required
        />
      </Form.Group>
      <Form.Group controlId="formConfirmPassword" className="mb-3">
        <Form.Label>Confirm Password</Form.Label>
        <Form.Control
          type="password"
          name="confirmPassword"
          value={changePasswordForm.confirmPassword}
          onChange={(e) => setChangePasswordForm({ ...changePasswordForm, confirmPassword: e.target.value })}
          required
        />
      </Form.Group>
      <Button variant="primary" onClick={handlePasswordChange} disabled={isSaving}>
        {isSaving ? 'Saving...' : 'Change Password'}
      </Button>
    </Form>
  </Card.Body>
</Card>

      <Modal show={showCropperModal} onHide={() => setShowCropperModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Crop Avatar</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {src && ( 
            <Cropper
              style={{ height: 400, width: '100%' }}
              aspectRatio={1}
              guides={true}
              src={src}
              onInitialized={(instance) => setCropper(instance)}
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowCropperModal(false)}>Cancel</Button>
          <Button variant="primary" onClick={handleCrop}>Crop Image</Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default Profile;
