import React from "react";
import { Link } from "react-router-dom";
import { resetPassword } from "../../../services/ccms/userManagement/account/account_endpoints";

// image
import logo from "../../../images/logo-full.png";

const ResetPassword = ({ code }) => {
  const [resetPasswordData, setResetPasswordData] = React.useState({
    email: "",
    password: "",
    confirmPassword: "",
    code: code,
  });
  const [loading, setLoading] = React.useState(false);
  const [responseTitle, setResponseTitle] = React.useState('');
  const [responseMessage, setResponseMessage] = React.useState('');
  const [status, setStatus] = React.useState('');

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await resetPassword(resetPasswordData);
      setStatus(response.status);
      setResponseTitle(response.title || "Success");
      setResponseMessage(response.message || "Your password has been reset successfully.");
      setLoading(false);
    } catch (error) {
      console.error('Error occurred while resetting password:', error);
      setStatus("Error");
      setResponseTitle("Error");
      setResponseMessage("An error occurred while resetting your password. Please try again.");
      setLoading(false);
    }
  };

  return (
    <div className="authincation h-100 p-meddle">
      <div className="container h-100">
        <div className="row justify-content-center h-100 align-items-center">
          <div className="col-md-8 col-sm-10">
            <div className="authincation-content" style={{ marginTop: "20vh" }}>
              <div className="row no-gutters">
                <div className="col-xl-12">
                  <div className="auth-form">
                    <div className="text-center mb-3">
                      <Link to="/app/dashboard">
                        <img src={logo} alt="logo" />
                      </Link>
                    </div>
                    <h4 className="text-center mb-4">Reset Password</h4>
                    {!responseMessage ? (
                      <form onSubmit={onSubmit}>
                        <div className="form-group">
                          <label>
                            <strong>Email</strong>
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            value={resetPasswordData.email}
                            onChange={(e) =>
                              setResetPasswordData({
                                ...resetPasswordData,
                                email: e.target.value,
                              })
                            }
                            disabled={loading}
                          />
                        </div>
                        <div className="form-group">
                          <label>
                            <strong>Password</strong>
                          </label>
                          <input
                            type="password"
                            className="form-control"
                            value={resetPasswordData.password}
                            onChange={(e) =>
                              setResetPasswordData({
                                ...resetPasswordData,
                                password: e.target.value,
                              })
                            }
                            disabled={loading}
                          />
                        </div>
                        <div className="form-group">
                          <label>
                            <strong>Confirm Password</strong>
                          </label>
                          <input
                            type="password"
                            className="form-control"
                            value={resetPasswordData.confirmPassword}
                            onChange={(e) =>
                              setResetPasswordData({
                                ...resetPasswordData,
                                confirmPassword: e.target.value,
                              })
                            }
                            disabled={loading}
                          />
                        </div>
                        <div className="text-center mt-3">
                          <button
                            type="submit"
                            className="btn btn-primary btn-block"
                            disabled={loading}
                          >
                            {loading ? (
                              <>
                                <span
                                  className="spinner-border spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>{" "}
                                Submitting...
                              </>
                            ) : (
                              "SUBMIT"
                            )}
                          </button>
                        </div>
                      </form>
                    ) : (
                      <div
                        className={`alert mt-3 ${
                          status === "Success" ? "alert-success" : "alert-danger"
                        }`}
                      >
                        <h4>{responseTitle}</h4>
                        <p>{responseMessage}</p>
                        {status === "Success" && (
                          <Link to="/auth/login" className="btn btn-link">
                            Go to Login
                          </Link>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
