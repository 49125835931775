import React, { createContext, useState, useContext, useEffect } from 'react';
import { DepartmentList, DepartmentListView, DesignationsList } from './departmentManagement/department/department_endpoints';
import { FilteredTaskViewList, GetMyTasks, GetAssignedUser } from './taskManagement/tasks/tasks_endpoints';
import { FeedbackCategoryList } from './system/feedbackCategory';
import { FeedbackTypeList } from './system/feedbackType';
import { FeedbackListView } from './system/feedback';
import { addTaskToQueue } from './taskQueue';
import useCacheRefresher from './useCacheRefresher';
import { getConfig } from '../../configs/pubConfig'; // Adjust path as necessary
import { getUserRights } from '../../services/ccms/userManagement/user/user_endpoints'; // Ensure this is the correct path
import { LeaveTypeList } from './hrManagement/leaveType';
import { LeaveStatusList } from './hrManagement/leaveStatus';
import { PolicyListView } from './hrManagement/policy';
import { PolicyCategoryList } from './hrManagement/policyCategory';
import { LeaveListViewForUser } from './hrManagement/leaveRequest';
import { EmployeeTypeList } from './hrManagement/employeeType';
import { GetEmployeeView } from './hrManagement/employee';
import { MaritalStatusList } from './memberManagement/marital_status/marital_status_endpoints';
import { GetMemberAddressByUserId } from './memberManagement/member_in_address/member_in_address_endpoints';
import { MemberByUserId } from './memberManagement/member/member_endpoint';
import { EmployeeFileListByUserId } from './hrManagement/employeeFile';
import { EmployeeFileCategoryList } from './hrManagement/employeeFileCategory';
import { EmployeeFileTypeList } from './hrManagement/employeeFileType';
import { PolicySignatureListViewByUserId } from './hrManagement/policySignature';
import { LeaveDocumentListView } from './hrManagement/leaveDocument';


const DataContext = createContext();

export const DataProvider = ({ children }) => {
  const [userRights, setUserRights] = useState([]);
  const [departmentList, setDepartmentList] = useState(null);
  const [departmentListView, setDepartmentListView] = useState(null);
  const [designationsList, setDesignationsList] = useState(null);
  const [feedbackCategoryList, setFeedbackCategoryList] = useState(null);
  const [feedbackTypeList, setFeedbackTypeList] = useState(null);
  const [feedbackListView, setFeedbackListView] = useState(null);
  const [filteredData, setFilteredData] = useState(null);
  const [leaveTypes, setLeaveTypes] = useState(null);
  const [leaveStatus, setLeaveStatus] = useState(null);
  const [policy, setPolicy] = useState(null);
  const [policyCategory, setPolicyCategory] = useState(null);
  const [myTasks, setMyTasks] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [CACHE_DURATION, setCacheDuration] = useState(60000); // Default to 1 minute (60000 ms)
  const [leaveData, setLeaveData] = useState(null);
  const [employeeType, setEmployeeType] = useState(null);
  const [employeeView, setEmployeeView] = useState(null);
  const [maritalStatusList, setMaritalStatusList] = useState(null);
  const [memberAddress, setMemberAddress] = useState(null);
  const [memberDetails, setMemberDetails] = useState(null);
  const [employeeFile, setEmployeeFile] = useState(null);
  const [employeeFileCategory, setEmployeeFileCategory] = useState(null);
  const [employeeFileType, setEmployeeFileType] = useState(null);
  const [employeePolicySignature, setEmployeePolicySignature] = useState(null);
  const [userId, setUserId] = useState(null);

  const hasRequiredRights = (requiredRights) => {
    return requiredRights.every(right => userRights.includes(right));
};



  useEffect(() => {
    const fetchConfig = async () => {
      const config = await getConfig();
      const cacheDuration = config.CACHE_REFRESHER_DURATION || 2; // Default to 1 minute if not provided
      const cacheDurationInMs = cacheDuration * 60000; // Convert minutes to milliseconds
      setCacheDuration(cacheDurationInMs); // Convert minutes to milliseconds      
    };

    const fetchUserId = async () => {
      const userID = localStorage.getItem('userID');
      setUserId(userID);
    };
    
    fetchUserId();
    fetchConfig();
  }, []);

  const fetchData = async (forceUpdate = false) => {
    const userID = localStorage.getItem('userID');
    if(userId === null) {
      setUserId(userID);
    }

    setIsLoading(true);
      try {
        const [
          departmentListData,
          departmentListViewData,
          designationsListData,
          filteredDataResponse,
          myTasksResponse,
          feedbackCategoryList,
          feedbackTypeList,
          feedbackListViewData,
          leaveTypesData,
          leaveStatusData,
          policyData,
          policyCategoryData,
          leaveData,
          employeeTypeData,
          employeeViewData,
          maritalStatusList,
          memberAddressData,
          memberDetailsData,
          employeFileData,
          employeeFileCategoryData,
          employeeFileTypeData,
          employeePolicySignatureData,
        ] = await Promise.allSettled([
          DepartmentList(),
          DepartmentListView(),
          DesignationsList(),
          FilteredTaskViewList(),
          GetMyTasks(),
          FeedbackCategoryList(),
          FeedbackTypeList(),
          FeedbackListView(),
          LeaveTypeList(),
          LeaveStatusList(),
          PolicyListView(),
          PolicyCategoryList(),
          LeaveListViewForUser(userID),
          EmployeeTypeList(),
          GetEmployeeView(userID),
          MaritalStatusList(),
          GetMemberAddressByUserId(userID),
          MemberByUserId(userID),
          EmployeeFileListByUserId(userID),
          EmployeeFileCategoryList(),
          EmployeeFileTypeList(),
          PolicySignatureListViewByUserId(userID),
        ]);

        setDepartmentList(departmentListData.status === 'fulfilled' ? departmentListData.value : null);
        setDepartmentListView(departmentListViewData.status === 'fulfilled' ? departmentListViewData.value : null);
        setDesignationsList(designationsListData.status === 'fulfilled' ? designationsListData.value : null);
        setFilteredData(filteredDataResponse.status === 'fulfilled' ? filteredDataResponse.value : null);
        setMyTasks(myTasksResponse.status === 'fulfilled' ? myTasksResponse.value : null);
        setFeedbackCategoryList(feedbackCategoryList.status === 'fulfilled' ? feedbackCategoryList.value : null);
        setFeedbackTypeList(feedbackTypeList.status === 'fulfilled' ? feedbackTypeList.value : null);
        setFeedbackListView(feedbackListViewData.status === 'fulfilled' ? feedbackListViewData.value : null);
        setLeaveTypes(leaveTypesData.status === 'fulfilled' ? leaveTypesData.value : null);
        setLeaveStatus(leaveStatusData.status === 'fulfilled' ? leaveStatusData.value : null);
        setPolicy(policyData.status === 'fulfilled' ? policyData.value : null);
        setPolicyCategory(policyCategoryData.status === 'fulfilled' ? policyCategoryData.value : null);
        setLeaveData(leaveData.status === 'fulfilled' ? leaveData.value : null);
        setEmployeeType(employeeTypeData.status === 'fulfilled' ? employeeTypeData.value : null);
        setEmployeeView(employeeViewData.status === 'fulfilled' ? employeeViewData.value : null);
        setMaritalStatusList(maritalStatusList.status === 'fulfilled' ? maritalStatusList.value : null);
        setMemberAddress(memberAddressData.status === 'fulfilled' ? memberAddressData.value : null);
        setMemberDetails(memberDetailsData.status === 'fulfilled' ? memberDetailsData.value : null);
        setEmployeeFile(employeFileData.status === 'fulfilled' ? employeFileData.value : null);
        setEmployeeFileCategory(employeeFileCategoryData.status === 'fulfilled' ? employeeFileCategoryData.value : null);
        setEmployeeFileType(employeeFileTypeData.status === 'fulfilled' ? employeeFileTypeData.value : null);
        setEmployeePolicySignature(employeePolicySignatureData.status === 'fulfilled' ? employeePolicySignatureData.value : null);


        if (filteredDataResponse.status === 'fulfilled' && filteredDataResponse.value && filteredDataResponse.value.results) {
          const taskIDs = filteredDataResponse.value.results.map(task => task.taskID);

          // Fetch assigned users for each task
          //const assignedUserPromises = taskIDs.map(taskID => GetAssignedUser(taskID, true));
          //const assignedUsers = await Promise.allSettled(assignedUserPromises);

          // Merge assigned users back into filtered data results
          const updatedFilteredData = filteredDataResponse.value.results.map((task, index) => ({
            ...task,
            //assignedUser: assignedUsers[index].status === 'fulfilled' ? assignedUsers[index].value : null
          }));

          setFilteredData({ ...filteredDataResponse.value, results: updatedFilteredData });
        }
      } catch (error) {
        console.error('Data fetch error:', error);
      } finally {
        setIsLoading(false);
      }
  };

  useEffect(() => {
    fetchData(); // Initial data fetch

    const intervalId = setInterval(() => fetchData(), CACHE_DURATION); // Background data refresh

    return () => clearInterval(intervalId);
  }, []);

  useCacheRefresher(); // This will start the background task

  return (
    <DataContext.Provider value={{ departmentList, departmentListView, designationsList, filteredData, myTasks, isLoading }}>
      {children}
    </DataContext.Provider>
  );
};

export const useData = () => useContext(DataContext);
