import React from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  PieChart,
  Pie,
  Legend,
  ResponsiveContainer,
  LineChart,
  Line,
  CartesianGrid,
  Cell
} from 'recharts';

const NoDataMessage = () => (
  <div style={{ textAlign: 'center', paddingTop: '50px', color: '#999' }}>
    <strong>No Data</strong>
  </div>
);

const TaskGraphs = ({
    tasksByStatusData,
    tasksByPriorityData,
    tasksByLocationData,
    tasksByDepartmentData,
    tasksOverTimeData,
    dateFormatMode,
    tasksOverdueByStatusData // New prop
  }) => {

  const formatDateTick = (dateStr) => {
    const date = new Date(dateStr);
    const options = {};
    if (dateFormatMode === 'dd') {
      // Same month/year, show day only
      options.day = '2-digit';
    } else if (dateFormatMode === 'MMM') {
      // Same year, multiple months, show month only
      options.month = 'short';
    } else {
      // Different years -> MMM yyyy
      options.month = 'short';
      options.year = 'numeric';
    }
    return date.toLocaleDateString(undefined, options);
  };

  // For Departments: Calculate a suitable width
  // For example, 10px per department. If too many departments, width grows
  const departmentChartWidth = Math.max(tasksByDepartmentData.length * 10, 800);

  return (
    <div className="mb-4">
      <h2>Graphs</h2>

      {/* Tasks by Status */}
      <Row className="mb-4">
        <Col md={6}>
          <Card>
            <Card.Header>Tasks by Status</Card.Header>
            <Card.Body style={{ height: '300px' }}>
              {tasksByStatusData.length === 0 ? <NoDataMessage /> : (
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart data={tasksByStatusData}>
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="count">
                      {tasksByStatusData.map((entry, index) => (
                        <Cell key={`cell-status-${index}`} fill={entry.color} />
                      ))}
                    </Bar>
                  </BarChart>
                </ResponsiveContainer>
              )}
            </Card.Body>
          </Card>
        </Col>

        {/* Tasks by Priority */}
        <Col md={6}>
          <Card>
            <Card.Header>Tasks by Priority</Card.Header>
            <Card.Body style={{ height: '300px' }}>
              {tasksByPriorityData.length === 0 ? <NoDataMessage /> : (
                <ResponsiveContainer width="100%" height="100%">
                  <PieChart>
                    <Pie
                      data={tasksByPriorityData}
                      dataKey="value"
                      nameKey="name"
                      cx="50%"
                      cy="50%"
                      outerRadius={80}
                      label
                    >
                      {tasksByPriorityData.map((entry, index) => (
                        <Cell key={`cell-priority-${index}`} fill={entry.color} />
                      ))}
                    </Pie>
                    <Tooltip />
                    <Legend />
                  </PieChart>
                </ResponsiveContainer>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>

<Row className="mb-4">
      {/* Tasks by Location */}
      <Col md={6}>
          <Card>
            <Card.Header>Tasks by Location</Card.Header>
            <Card.Body style={{ height: '300px' }}>
              {tasksByLocationData.length === 0 ? <NoDataMessage /> : (
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart data={tasksByLocationData}>
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="count">
                      {tasksByLocationData.map((entry, index) => (
                        <Cell key={`cell-loc-${index}`} fill={entry.color} />
                      ))}
                    </Bar>
                  </BarChart>
                </ResponsiveContainer>
              )}
            </Card.Body>
          </Card>
        </Col>
      
      {/* Overdue Tasks by Status */}
      <Col md={6}>
          <Card>
            <Card.Header>Overdue Tasks by Status</Card.Header>
            <Card.Body style={{ height: '300px' }}>
              {(!tasksOverdueByStatusData || tasksOverdueByStatusData.length === 0) ? (
                <NoDataMessage />
              ) : (
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart data={tasksOverdueByStatusData}>
                    <CartesianGrid stroke="#ccc" strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="value" barSize={20}>
                      {tasksOverdueByStatusData.map((entry, index) => (
                        <Cell key={`cell-ovstatus-${index}`} fill={entry.color} />
                      ))}
                    </Bar>
                  </BarChart>
                </ResponsiveContainer>
              )}
            </Card.Body>
          </Card>
        </Col>
        </Row>

      {/* Tasks by Department */}
      <Row className="mb-4">
        <Col md={12}>
          <Card>
            <Card.Header>Tasks by Department</Card.Header>
            <Card.Body style={{ height: '400px', overflowX: 'auto' }}>
              {tasksByDepartmentData.length === 0 ? (
                <NoDataMessage />
              ) : (
                <div style={{ width: '100%', height: '100%' }}>
                  <ResponsiveContainer width="100%" height="100%">
                    <BarChart data={tasksByDepartmentData} margin={{ top: 20, bottom: 20, left: 20, right: 20 }}>
                      <CartesianGrid stroke="#ccc" strokeDasharray="3 3" />
                      <XAxis 
                        dataKey="name" 
                        interval={0}
                        tick={{ angle: -30, textAnchor: 'end' }}
                        height={80}
                      />
                      <YAxis />
                      <Tooltip />
                      <Bar dataKey="value" barSize={4} fill="#82ca9d" />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Tasks Over Time */}
      <Row>
        <Col md={12}>
          <Card>
            <Card.Header>Tasks Over Time</Card.Header>
            <Card.Body style={{ height: '300px' }}>
              {tasksOverTimeData.length === 0 ? <NoDataMessage /> : (
                <ResponsiveContainer width="100%" height="100%">
                  <LineChart data={tasksOverTimeData}>
                    <CartesianGrid stroke="#ccc" />
                    <XAxis dataKey="date" tickFormatter={formatDateTick} 
                        tick={{ angle: -30, textAnchor: 'end' }}
                        height={60} />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line type="monotone" dataKey="count" stroke="#ff7300" />
                  </LineChart>
                </ResponsiveContainer>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default TaskGraphs;
