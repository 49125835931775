// import React, { lazy, Suspense, useEffect, useState } from 'react';
// import { connect, useDispatch } from 'react-redux';
// import { Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom';
// import { Modal, Button } from 'react-bootstrap'; // Import Bootstrap Modal and Button
// import { checkAutoLogin } from './services/AuthService';
// import { isAuthenticated } from './redux/selectors/AuthSelectors';
// import Index from './views';
// import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
// import "./css/style.css";
// import { UserRightsProvider } from './context/UserRightsContext';
// import axios from 'axios';
// import packageJson from '../package.json'; // Import the whole package.json
// import ResetPassword from './views/pages/auth/ResetPassword';

// const ForgotPassword = lazy(() => import('./views/pages/auth/ForgotPassword'));
// const Login = lazy(() => {
//   return new Promise(resolve => {
//     setTimeout(() => resolve(import('./views/pages/auth/Login')), 500);
//   });
// });

// // Public Error Pages
// const LockScreen = lazy(() => import("./views/pages/auth/LockScreen"));
// const Error400 = lazy(() => import("./views/pages/error/Error400"));
// const Error403 = lazy(() => import("./views/pages/error/Error403"));
// const Error404 = lazy(() => import("./views/pages/error/Error404"));
// const Error500 = lazy(() => import("./views/pages/error/Error500"));
// const Error503 = lazy(() => import("./views/pages/error/Error503"));

// function withRouter(Component) {
//   function ComponentWithRouterProp(props) {
//     let location = useLocation();
//     let navigate = useNavigate();
//     let params = useParams();

//     return (
//       <Component
//         {...props}
//         router={{ location, navigate, params }}
//       />
//     );
//   }

//   return ComponentWithRouterProp;
// }

// function App(props) {
//   const [newVersionAvailable, setNewVersionAvailable] = useState(false);
//   const dispatch = useDispatch();
//   const navigate = useNavigate();
//   const [releaseNotes, setReleaseNotes] = useState([]);
//   const currentVersion = packageJson.version;

//   useEffect(() => {
//     checkAutoLogin(dispatch, navigate);
//   }, [dispatch, navigate]);

//   useEffect(() => {
//     const checkForUpdates = async () => {
//       try {
//         const response = await axios.get('/meta.json');
//         const { version: latestVersion, releaseNotes: notes } = response.data;
  
//         if (latestVersion !== currentVersion) {
//           setReleaseNotes(notes || []);
//           setNewVersionAvailable(true);
//         }
//       } catch (error) {
//         console.error('Error checking for updates:', error);
//       }
//     };
  
//     checkForUpdates();
//   }, [currentVersion]);

//   // Function to force a hard reload of the app
//   const refreshApp = () => {
//     localStorage.clear();
//     window.location.reload(true);
//   };

//   // Function to determine the appropriate reload message
//   const getReloadMessage = () => {
//     const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
//     return isMac ? "Press Cmd + R to refresh." : "Press Ctrl + F5 to refresh.";
//   };

//   return (
//     <div className="App">
//       <Suspense fallback={
//         <div id="preloader">
//           <div className="sk-three-bounce">
//             <div className="sk-child sk-bounce1"></div>
//             <div className="sk-child sk-bounce2"></div>
//             <div className="sk-child sk-bounce3"></div>
//           </div>
//         </div>
//       }>
//         <UserRightsProvider>
//           <Routes>
//             {/* Public Routes */}
//             <Route path='/auth/login' element={<Login />} />
//             <Route path='/auth/forgot-password' element={<ForgotPassword />} />
//             <Route path="/lock-screen" element={<LockScreen />} />
//             <Route path="/error-400" element={<Error400 />} />
//             <Route path="/error-403" element={<Error403 />} />
//             <Route path="/error-404" element={<Error404 />} />
//             <Route path="/error-500" element={<Error500 />} />
//             <Route path="/error-503" element={<Error503 />} />            
//             <Route path="/auth/reset-password" element={<ResetPassword />} />
//             {/* <Route path="*" element={<Error404 />} /> Fallback route for unmatched paths */}

            
//             {/* Protected Route */}
//             {props.isAuthenticated && (
//               <Route path="/*" element={<Index />} />
//             )}
//           </Routes>
          
//         </UserRightsProvider>
//       </Suspense>

//       {/* Modal for new version available notification */}
//       <Modal
//         show={newVersionAvailable}
//         onHide={() => {}}
//         backdrop="static"
//         keyboard={false}
//         centered
//       >
//         <Modal.Header>
//           <Modal.Title>New Version Available</Modal.Title>
//         </Modal.Header>

//         <Modal.Body>
//   <p>A new version of this application is available. Please update to enjoy the latest features and improvements.</p>
//   {releaseNotes.length > 0 ? (
//     <ul>
//       {releaseNotes.map((note, index) => (
//         <li key={index}>{note}</li>
//       ))}
//     </ul>
//   ) : (
//     <p>No release notes available.</p>
//   )}
//   <p>{getReloadMessage()}</p>
// </Modal.Body>
//         <Modal.Footer>
//           <Button variant="secondary" size='sm' onClick={() => setNewVersionAvailable(false)}> 
//             Skip
//           </Button>
//           <Button variant="primary" size='sm' onClick={refreshApp}>
//             Update Now
//           </Button>
//         </Modal.Footer>
//       </Modal>
//     </div>
//   );
// }

// const mapStateToProps = (state) => ({
//   isAuthenticated: isAuthenticated(state),
// });

// export default withRouter(connect(mapStateToProps)(App));

import React, { lazy, Suspense, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import { checkAutoLogin } from './services/AuthService';
import { isAuthenticated } from './redux/selectors/AuthSelectors';
import Index from './views';
import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./css/style.css";
import { UserRightsProvider } from './context/UserRightsContext';
import { DataProvider } from './services/ccms/DataProvider'; // Import DataProvider
import axios from 'axios';
import packageJson from '../package.json';
import ResetPassword from './views/pages/auth/ResetPassword';
import ClearCachePage from './ClearCachePage';

const ForgotPassword = lazy(() => import('./views/pages/auth/ForgotPassword'));
const Login = lazy(() => {
  return new Promise(resolve => {
    setTimeout(() => resolve(import('./views/pages/auth/Login')), 500);
  });
});

// Public Error Pages
const LockScreen = lazy(() => import("./views/pages/auth/LockScreen"));
const Error400 = lazy(() => import("./views/pages/error/Error400"));
const Error403 = lazy(() => import("./views/pages/error/Error403"));
const Error404 = lazy(() => import("./views/pages/error/Error404"));
const Error500 = lazy(() => import("./views/pages/error/Error500"));
const Error503 = lazy(() => import("./views/pages/error/Error503"));

function App(props) {
  const { isAuthenticated } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [newVersionAvailable, setNewVersionAvailable] = useState(false);
  const [releaseNotes, setReleaseNotes] = useState([]);
  const currentVersion = packageJson.version;

  useEffect(() => {
    checkAutoLogin(dispatch, navigate);
  }, [dispatch, navigate]);

  useEffect(() => {
    const checkForUpdates = async () => {
      try {
        const response = await axios.get('/meta.json');
        const { version: latestVersion, releaseNotes: notes } = response.data;

        if (latestVersion !== currentVersion) {
          setReleaseNotes(notes || []);
          setNewVersionAvailable(true);
        }
      } catch (error) {
        console.error('Error checking for updates:', error);
      }
    };

    checkForUpdates();
  }, [currentVersion]);

  const refreshApp = () => {
    localStorage.clear();
    window.location.reload(true);
  };

  const getReloadMessage = () => {
    const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
    return isMac ? "Press Cmd + R to refresh." : "Press Ctrl + F5 to refresh.";
  };

  return (
    <div className="App">
      <Suspense fallback={<div id="preloader"><div className="sk-three-bounce"><div className="sk-child sk-bounce1"></div><div className="sk-child sk-bounce2"></div><div className="sk-child sk-bounce3"></div></div></div>}>
        <Routes>
          {/* Public Routes */}
          <Route path='/auth/login' element={<Login />} />
          <Route path='/auth/forgot-password' element={<ForgotPassword />} />
          <Route path="/lock-screen" element={<LockScreen />} />
          <Route path="/error-400" element={<Error400 />} />
          <Route path="/error-403" element={<Error403 />} />
          <Route path="/error-404" element={<Error404 />} />
          <Route path="/error-500" element={<Error500 />} />
          <Route path="/error-503" element={<Error503 />} />
          <Route path="/auth/reset-password" element={<ResetPassword />} />
          <Route path="/sys/reset" element={<ClearCachePage />} />

          {/* Protected Routes */}
          {isAuthenticated && (
            <Route
              path="/*"
              element={
                <UserRightsProvider>
                  <DataProvider>
                    <Index />
                  </DataProvider>
                </UserRightsProvider>
              }
            />
          )}
        </Routes>
      </Suspense>

      {/* Modal for new version available notification */}
      <Modal show={newVersionAvailable} onHide={() => {}} backdrop="static" keyboard={false} centered>
        <Modal.Header>
          <Modal.Title>New Version Available</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>A new version of this application is available. Please update to enjoy the latest features and improvements.</p>
          {releaseNotes.length > 0 ? (            
            <>
              <h6>Release Notes:</h6>
              <ul>{releaseNotes.map((note, index) => <li key={index}>- {note}</li>)}</ul>
            </>
          ) : (
            <p>No release notes available.</p>
          )}
          <hr />
          <p>{getReloadMessage()}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" size="sm" onClick={() => setNewVersionAvailable(false)}>
            Skip
          </Button>
          <Button variant="primary" size="sm" onClick={refreshApp}>
            Update Now
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

const mapStateToProps = (state) => ({
  isAuthenticated: isAuthenticated(state),
});

export default connect(mapStateToProps)(App);
