// MyDetails.js

import React, { useState, useEffect, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Tab, Nav, Modal, Card } from 'react-bootstrap';
import Swal from 'sweetalert2';
import Shimmer from '../../../../components/Custom/Loading/Shimmer';
import EmployeeHeader from './parts/header';
import DetailsTab from './parts/DetailsTab';
import DutiesTab from './parts/DutiesTab';
import FilesTab from './parts/FilesTab';
import PoliciesTab from './parts/PoliciesTab';
import { SaveEmployee, UpdateEmployee, GetEmployeeView } from '../../../../../services/ccms/hrManagement/employee';
import { EmployeeTypeList } from '../../../../../services/ccms/hrManagement/employeeType';
import EmploymentForm from './parts/EmploymentForm';

const MyDetails = () => {
  const userDetails = JSON.parse(localStorage.getItem('userDetails'));
  const [employeeView, setEmployeeView] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [employeeTypes, setEmployeeTypes] = useState([]);
  const [isNewEmployee, setIsNewEmployee] = useState(false);
  const [showEmployeeModal, setShowEmployeeModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);

        const employeeId = userDetails?.userId;
        if (!employeeId) {
          Swal.fire({
            title: 'Error',
            text: 'Employee ID not found',
            icon: 'error',
          });
          return;
        }

        const response = await GetEmployeeView(employeeId);
        if (response.status === 'Success') {
          if (!response.results) {
            setIsNewEmployee(true);
            // setShowEmployeeModal(true);
          } else {
            setEmployeeView(response.results);
            setIsNewEmployee(false);
          }
        }

        // Fetch employee types
        const typesResponse = await EmployeeTypeList();
        setEmployeeTypes(typesResponse.results);
      } catch (error) {
        console.error('Error fetching data:', error);
        Swal.fire({
          title: 'Error',
          text: 'An error occurred while fetching data',
          icon: 'error',
        });
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleSaveEmployee = async (newEmployee) => {
    try {
      const response = await SaveEmployee(newEmployee);
      if (response.status === 'Success') {
        Swal.fire({
          title: 'Success',
          text: 'Employee saved successfully',
          icon: 'success',
        }).then(() => {
          window.location.reload();
        });
      } else {
        Swal.fire({
          title: 'Error',
          text: response.message || 'An error occurred while saving the employee',
          icon: 'error',
        });
      }
    } catch (error) {
      console.error('Error saving employee:', error);
      Swal.fire({
        title: 'Error',
        text: 'An error occurred while saving the employee',
        icon: 'error',
      });
    }
  };

  const handleUpdateEmployee = async (updatedEmployee) => {
    try {
      const response = await UpdateEmployee(updatedEmployee);
      if (response.status === 'Success') {
        setEmployeeView(updatedEmployee);
        Swal.fire({
          title: 'Success',
          text: 'Employee updated successfully',
          icon: 'success',
        });
      } else {
        Swal.fire({
          title: 'Error',
          text: response.message || 'An error occurred while updating the employee',
          icon: 'error',
        });
      }
    } catch (error) {
      console.error('Error updating employee:', error);
      Swal.fire({
        title: 'Error',
        text: 'An error occurred while updating the employee',
        icon: 'error',
      });
    }
  };

  return (
    <Fragment>
      {isLoading ? (
        <Shimmer type="page" />
      ) : employeeView && employeeView.userId ? (
        <>
          <EmployeeHeader className="mt-5" employee={employeeView} />

          <div className="card">
            <div className="card-body">
              <div className="profile-tab">
                <div className="custom-tab-1">
                  <Tab.Container defaultActiveKey="Details">
                    <Nav as="ul" className="nav nav-tabs">
                      <Nav.Item as="li" className="nav-item">
                        <Nav.Link eventKey="Details">Details</Nav.Link>
                      </Nav.Item>
                      {/* <Nav.Item as="li" className="nav-item">
                        <Nav.Link eventKey="Duties">Duties</Nav.Link>
                      </Nav.Item> */}
                      <Nav.Item as="li" className="nav-item">
                        <Nav.Link eventKey="Files">Files</Nav.Link>
                      </Nav.Item>
                      <Nav.Item as="li" className="nav-item">
                        <Nav.Link eventKey="Policies">Policies</Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <Tab.Content>
                      <Tab.Pane eventKey="Details">
                        <DetailsTab userId={employeeView.userId} />
                      </Tab.Pane>
                      {/* <Tab.Pane eventKey="Duties">
                        <DutiesTab userId={employeeView.userId} />
                      </Tab.Pane> */}
                      <Tab.Pane eventKey="Files">
                        <FilesTab userId={employeeView.userId} />
                      </Tab.Pane>
                      <Tab.Pane eventKey="Policies">
                        <PoliciesTab userId={employeeView.userId} />
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <Card className='shadow'>
          <Card.Body>
          <EmploymentForm
                userId={userDetails.userId}
                onSave={handleSaveEmployee}
                employeeTypes={employeeTypes}
              />
              </Card.Body>
        </Card>
      )}
    </Fragment>
  );
};

export default MyDetails;
