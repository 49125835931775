import React, { useState, useEffect } from 'react';

function PDFViewer({ pdfUrl }) {
  const [pdfBytes, setPdfBytes] = useState(null);

  useEffect(() => {
    const fetchPdf = async () => {
      try {
        const response = await fetch(pdfUrl);
        const blob = await response.blob();
        const bytes = await blob.arrayBuffer();
        setPdfBytes(bytes);
      } catch (error) {
        console.error('Error fetching PDF:', error);
      }
    };

    fetchPdf();
  }, [pdfUrl]);

  return (
    <div>
      {pdfBytes && (
        <iframe
          title="PDF Preview"
          src={`data:application/pdf;base64,${btoa(
            new Uint8Array(pdfBytes).reduce(
              (data, byte) => data + String.fromCharCode(byte),
              '',
            ),
          )}`}
          style={{ width: '100%', height: '600px' }}
        />
      )}
    </div>
  );
}

export default PDFViewer;