import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { forgotPassword } from "../../../services/ccms/userManagement/account/account_endpoints";
import logo from "../../../images/logo-full.png";

const ForgotPassword = () => {
  const navigate = useNavigate();	
  const [forgotPasswordData, setForgotPasswordData] = React.useState({
    email: "",
  });
  const [loading, setLoading] = React.useState(false);
  const [responseTitle, setResponseTitle] = React.useState('');
  const [responseMessage, setResponseMessage] = React.useState('');
  const [status, setStatus] = React.useState('');

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await forgotPassword(forgotPasswordData);
      setStatus(response.status);
      setResponseTitle(response.title);
      setResponseMessage(response.message);
      if (response.status === "Success") {
        console.log('Password reset email sent:', response);
      } else {
        console.error('Error occurred while resetting password:', response);
      }
    } catch (error) {
      console.error('Error:', error);
      setStatus('Error');
      setResponseTitle('Error');
      setResponseMessage('An error occurred while resetting password.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="authincation h-100 p-meddle">
      <div className="container h-100">
        <div className="row justify-content-center h-100 align-items-center">
          <div className="col-md-8 col-sm-10">
            <div className="authincation-content" style={{ marginTop: '20vh' }}>
              <div className="row no-gutters">
                <div className="col-xl-12">
                  <div className="auth-form">
                    <div className="text-center mb-3">
                      <Link to="/app/dashboard">
                        <img src={logo} alt="" />
                      </Link>
                    </div>
                    <h4 className="text-center mb-4">Forgot Password</h4>
                    <form onSubmit={onSubmit}>
                      <div className="form-group">
                        <label>
                          <strong>Email</strong>
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          onChange={(e) =>
                            setForgotPasswordData({
                              ...forgotPasswordData,
                              email: e.target.value,
                            })
                          }
                          disabled={loading}
                        />
                      </div>
                      <div className="text-center mt-3">
                        <button
                          type="submit"
                          className="btn btn-primary btn-block"
                          disabled={loading}
                        >
                          {loading ? (
                            <>
                              <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>{' '}
                              Submitting...
                            </>
                          ) : (
                            'SUBMIT'
                          )}
                        </button>
                      </div>
                    </form>
                    {responseMessage && (
                      <div
                        className={`alert mt-3 ${
                          status === 'Success' ? 'alert-success' : 'alert-danger'
                        }`}
                      >
                        <h4>{responseTitle}</h4>
                        <p>{responseMessage}</p>
                        {status === 'Success' && (
                          <Link to="/auth/login" className="btn btn-link">
                            Go to Login
                          </Link>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
     </div>
    </div>
  );
};

export default ForgotPassword;
