import React from 'react';
import { Card, Row, Col } from 'react-bootstrap';

const CampusTaskStats = ({ stats }) => {
  // stats.starRating holds something like "★★★☆☆"
  return (
    <div className="mb-4">
      <h2>Campus Statistics</h2>
      <Row>
        <Col md={3}>
          <Card className="mb-3">
            <Card.Body>
              <h5>Total Tasks</h5>
              <h3>{stats.totalTasks || 0}</h3>
            </Card.Body>
          </Card>
        </Col>
        <Col md={3}>
          <Card className="mb-3">
            <Card.Body>
              <h5>Overdue Tasks</h5>
              <h3>{stats.overdueCount || 0}</h3>
            </Card.Body>
          </Card>
        </Col>
        <Col md={3}>
          <Card className="mb-3">
            <Card.Body>
              <h5>Completed Tasks</h5>
              <h3>{stats.completedCount || 0}</h3>
            </Card.Body>
          </Card>
        </Col>
        <Col md={3}>
          <Card className="mb-3">
            <Card.Body>
              <h5>On Hold Tasks</h5>
              <h3>{stats.onHoldCount || 0}</h3>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col md={3}>
          <Card className="mb-3">
            <Card.Body>
              <h5>Reopened Tasks</h5>
              <h3>{stats.reopenedCount || 0}</h3>
            </Card.Body>
          </Card>
        </Col>
        <Col md={3}>
          <Card className="mb-3">
            <Card.Body>
              <h5>High Priority</h5>
              <h3>{stats.highPriorityCount || 0}</h3>
            </Card.Body>
          </Card>
        </Col>
        <Col md={3}>
          <Card className="mb-3">
            <Card.Body>
              <h5>Medium Priority</h5>
              <h3>{stats.mediumPriorityCount || 0}</h3>
            </Card.Body>
          </Card>
        </Col>
        <Col md={3}>
          <Card className="mb-3">
            <Card.Body>
              <h5>Low Priority</h5>
              <h3>{stats.lowPriorityCount || 0}</h3>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      
      <Row>
        <Col md={3}>
          <Card className="mb-3">
            <Card.Body>
              <h5>Completion Rate</h5>
              <h3>{stats.completionRate ? stats.completionRate.toFixed(2) + '%' : '0%'}</h3>
              <h5>Rating: {stats.starRating || '★☆☆☆☆'}</h5>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default CampusTaskStats;
