import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Resizable } from 'react-resizable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTimes,
  faPlus,
  faMinus,
  faDownload,
  faPrint,
} from '@fortawesome/free-solid-svg-icons';
import 'react-resizable/css/styles.css';
import PDFViewer from './PDFViewer';

const FilePreview = ({ fileURL, onClose }) => {
  // Initial dimensions
  const initialWidth = 800;
  const initialHeight = 600;

  // State variables
  const [mimeType, setMimeType] = useState('');
  const [dimensions, setDimensions] = useState({ width: initialWidth, height: initialHeight });
  const [loadError, setLoadError] = useState(false);

  // Determine MIME type
  useEffect(() => {
    const getMimeType = () => {
      const extension = fileURL.split('.').pop().toLowerCase();
      const mimeTypes = {
        pdf: 'application/pdf',
        doc: 'application/msword',
        docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        xls: 'application/vnd.ms-excel',
        xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        ppt: 'application/vnd.ms-powerpoint',
        pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        txt: 'text/plain',
        jpg: 'image/jpeg',
        jpeg: 'image/jpeg',
        png: 'image/png',
        gif: 'image/gif',
        bmp: 'image/bmp',
        mp4: 'video/mp4',
        webm: 'video/webm',
        ogg: 'video/ogg',
        mov: 'video/quicktime',
        mp3: 'audio/mpeg',
        wav: 'audio/wav',
        // Add more as needed
      };
      setMimeType(mimeTypes[extension] || 'application/octet-stream');
    };
    getMimeType();
  }, [fileURL]);

  // Handle Escape key to close
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') onClose();
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [onClose]);

  // Zoom functions - adjust dimensions incrementally
  const handleZoomIn = () => {
    setDimensions((prevDimensions) => {
      const increment = 100;
      const newWidth = prevDimensions.width + increment;
      const newHeight = prevDimensions.height + increment;
      return {
        width: newWidth,
        height: newHeight,
      };
    });
  };

  const handleZoomOut = () => {
    setDimensions((prevDimensions) => {
      const decrement = 100;
      const newWidth = Math.max(prevDimensions.width - decrement, 300);
      const newHeight = Math.max(prevDimensions.height - decrement, 200);
      return {
        width: newWidth,
        height: newHeight,
      };
    });
  };

  // Handle resizing
  const onResize = (event, { size }) => {
    setDimensions(size);
  };

  // Handle Download
  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = fileURL;
    link.download = fileURL.split('/').pop();
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

// Handle Print
const handlePrint = () => {
  if (mimeType.startsWith('image/') || mimeType === 'application/pdf' || mimeType === 'text/plain') {
    // Create a new window
    const printWindow = window.open('', '_blank');
    // Build HTML content
    let content = '';
    if (mimeType.startsWith('image/')) {
      // For images
      content = `<html><head><title>Print Image</title></head><body style="margin:0;"><img src="${fileURL}" style="width:100%;height:auto;"></body></html>`;
    } else if (mimeType === 'application/pdf') {
      // For PDFs
      content = `<html><head><title>Print PDF</title></head><body style="margin:0;"><embed src="${fileURL}" type="application/pdf" style="width:100%;height:100%;"></embed></body></html>`;
    } else if (mimeType === 'text/plain') {
      // For text files
      content = `<html><head><title>Print Text</title></head><body style="margin:0;"><iframe src="${fileURL}" style="width:100%;height:100%;border:none;"></iframe></body></html>`;
    }

    // Write content to new window
    printWindow.document.open();
    printWindow.document.write(content);
    printWindow.document.close();
    printWindow.focus();

    // Delay printing to ensure content loads
    setTimeout(() => {
      printWindow.print();
      printWindow.close();
    }, 500);
  } else {
    alert('Printing is not supported for this file type.');
  }
};

  // Render controls
  const renderControls = () => (
    <div style={styles.controlsStyle}>
      <button onClick={handleDownload} style={styles.controlButtonStyle} aria-label="Download">
        <FontAwesomeIcon icon={faDownload} />
      </button>
      <button onClick={handlePrint} style={styles.controlButtonStyle} aria-label="Print">
        <FontAwesomeIcon icon={faPrint} />
      </button>
      <button onClick={handleZoomOut} style={styles.controlButtonStyle} aria-label="Zoom Out">
        <FontAwesomeIcon icon={faMinus} />
      </button>
      <button onClick={handleZoomIn} style={styles.controlButtonStyle} aria-label="Zoom In">
        <FontAwesomeIcon icon={faPlus} />
      </button>
    </div>
  );

  // Resizable content wrapper
  const ResizableContent = ({ children }) => {
    return (
      <Resizable
        width={dimensions.width}
        height={dimensions.height}
        onResize={onResize}
        minConstraints={[300, 200]}
        lockAspectRatio={
          mimeType.startsWith('image/') ||
          mimeType === 'application/pdf' ||
          mimeType === 'text/plain'
        }
      >
        <div
          style={{
            width: dimensions.width,
            height: dimensions.height,
            position: 'relative',
            margin: '20px',
            boxShadow: '0 0 10px rgba(0,0,0,0.2)',
            overflow: 'hidden',
          }}
        >
          {children}
        </div>
      </Resizable>
    );
  };

  ResizableContent.propTypes = {
    children: PropTypes.node.isRequired,
  };

  // Render content
  const renderContent = () => {
    if (loadError) {
      return (
        <div style={{ ...styles.containerStyle, textAlign: 'center' }}>
          <p style={{ color: 'red' }}>Failed to load document.</p>
          <a href={fileURL} download style={{ color: '#fff' }}>
            Download File
          </a>
        </div>
      );
    }

    const isMedia = mimeType.startsWith('video/') || mimeType.startsWith('audio/');
    const isResizable = !isMedia;

    const contentWrapper = (children) => {
      if (isResizable) {
        return <ResizableContent>{children}</ResizableContent>;
      }
      return (
        <div
          style={{
            width: '80%',
            maxWidth: '1000px',
            margin: '20px auto',
            boxShadow: '0 0 10px rgba(0,0,0,0.2)',
            padding: '20px',
          }}
        >
          {children}
        </div>
      );
    };

    // Office Documents
    if (
      [
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.ms-powerpoint',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      ].includes(mimeType)
    ) {
      const officeViewerUrl = `https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(
        fileURL
      )}`;
      return (
        <div style={styles.containerStyle}>
          {contentWrapper(
            <iframe
              src={officeViewerUrl}
              style={{ width: '100%', height: '100%', border: 'none' }}
              onError={() => setLoadError(true)}
              title="Office Document Preview"
            ></iframe>
          )}
        </div>
      );
    }

    // PDFs
    if (mimeType === 'application/pdf') {
      return (
        <div style={styles.containerStyle}>
          {contentWrapper(
            // PDFs
            <embed
              src={fileURL}
              type="application/pdf"
              style={{ width: '100%', height: '100%' }}
              onError={() => setLoadError(true)}
            />
          )}
        </div>
      );
    }

    // Images
    if (mimeType.startsWith('image/')) {
      const imageStyle = {
        width: '100%',
        height: '100%',
        objectFit: 'contain',
      };
      return (
        <div style={styles.containerStyle}>
          {contentWrapper(
            <img
              src={fileURL}
              alt="File Preview"
              style={imageStyle}
              onError={() => setLoadError(true)}
            />
          )}
        </div>
      );
    }

    // Video Files
    if (mimeType.startsWith('video/')) {
      return (
        <div style={styles.containerStyle}>
          {contentWrapper(
            <video
              controls
              style={{ width: '100%', height: '100%' }}
              src={fileURL}
              onError={() => setLoadError(true)}
            />
          )}
        </div>
      );
    }

    // Audio Files
    if (mimeType.startsWith('audio/')) {
      return (
        <div style={styles.containerStyle}>
          {contentWrapper(
            <audio
              controls
              style={{ width: '100%', height: '100%' }}
              src={fileURL}
              onError={() => setLoadError(true)}
            />
          )}
        </div>
      );
    }

    // Text Files
    if (mimeType === 'text/plain') {
      return (
        <div style={styles.containerStyle}>
          {contentWrapper(
            <iframe
              src={fileURL}
              style={{ width: '100%', height: '100%', border: 'none' }}
              onError={() => setLoadError(true)}
              title="Text File Preview"
            ></iframe>
          )}
        </div>
      );
    }

    // Fallback
    return (
      <div style={{ ...styles.containerStyle, textAlign: 'center' }}>
        <p style={{ color: '#fff' }}>Unable to preview this file type.</p>
        <a href={fileURL} download style={{ color: '#fff' }}>
          Download File
        </a>
      </div>
    );
  };

  return (
    <div style={styles.previewContainerStyle}>
      <button style={styles.closeButtonStyle} onClick={onClose} aria-label="Close Preview">
        <FontAwesomeIcon icon={faTimes} size="2x" />
      </button>
      {renderControls()}
      {renderContent()}
    </div>
  );
};



// PropTypes
FilePreview.propTypes = {
  fileURL: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

// Styles
const styles = {
  previewContainerStyle: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    zIndex: 1000,
    overflow: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  closeButtonStyle: {
    position: 'fixed',
    top: 20,
    right: 20,
    border: 'none',
    background: 'none',
    fontSize: 24,
    cursor: 'pointer',
    color: '#fff',
    zIndex: 1001,
  },
  controlsStyle: {
    position: 'fixed',
    top: 20,
    left: 20,
    display: 'flex',
    gap: 10,
    zIndex: 1001,
  },
  controlButtonStyle: {
    border: 'none',
    background: 'rgba(0,0,0,0.5)',
    color: '#fff',
    padding: '10px 15px',
    cursor: 'pointer',
    fontSize: 16,
    borderRadius: 4,
    transition: 'background 0.3s',
  },
  containerStyle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    overflow: 'auto',
    padding: 20,
    boxSizing: 'border-box',
  },
};

export default FilePreview;
