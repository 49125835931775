import React from 'react';
import { formatDistanceToNow, format, isValid, parseISO } from 'date-fns';

export const DateTimeConverter = ({ dateInput, dateFormat }) => {
  const formatDate = (dateInput, dateFormat) => {
    let date;
    if (typeof dateInput === 'string') {
      date = parseISO(dateInput);
    } else if (dateInput instanceof Date) {
      date = dateInput;
    } else {
      return 'Invalid date';
    }

    if (!isValid(date)) {
      return 'Invalid date';
    }

    return format(date, dateFormat);
  };

  return (
    <div>
      {formatDate(dateInput, dateFormat)}
    </div>
  );
};

export const FriendlyDateTime = ({ dateInput }) => {
  const formatDateToNow = (dateInput) => {
    let date;
    if (typeof dateInput === 'string') {
      date = parseISO(dateInput);
    } else if (dateInput instanceof Date) {
      date = dateInput;
    } else {
      return 'Invalid date';
    }

    if (!isValid(date)) {
      return 'Invalid date';
    }

    return formatDistanceToNow(date, { addSuffix: true });
  };

  return (
    <div>
      {formatDateToNow(dateInput)}
    </div>
  );
};

export const getDateTimeString = () => {
  const now = new Date();
  const isoString = now.toISOString();
  const formattedString = isoString.replace(/[^0-9]/g, "");
  return parseFloat(formattedString.substring(0, 14));
};