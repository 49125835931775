import React from 'react';
import { Card } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { TextColor } from '../../../../../../utilities/colorUtils';
import { formatDate } from '../../../../../../utilities/DateTimeManipulator';

const DepartmentTaskTable = ({ tasks, loading, overdueFilter, isOverdue }) => {
  const columns = [
    { name: 'Task ID', selector: row => row.taskID, sortable: true, width: '100px' },
    { name: 'Title', selector: row => row.title, wrap: true, sortable: true },
    { name: 'Due Date', selector: row => formatDate(row.dueDate), sortable: true, width: '150px' },
    {
      name: 'Priority',
      selector: row => row.priorityName,
      cell: row => (
        <span className='badge' style={{ backgroundColor: row.priorityColor || '#ccc' }}>
          <TextColor bgColor={row.priorityColor || '#ccc'} text={row.priorityName} />
        </span>
      ),
      sortable: true,
      width: '120px'
    },
    {
      name: 'Status',
      selector: row => row.statusName,
      cell: row => {
        const overdue = isOverdue(row);
        const displayName = overdue ? `Overdue - ${row.statusName}` : row.statusName;
        const statusColor = overdue ? '#FF0000' : (row.statusColor || '#8884d8');
        return (
          <span className='badge' style={{ backgroundColor: statusColor }}>
            <TextColor bgColor={statusColor} text={displayName} />
          </span>
        );
      },
      sortable: true,
      width: '150px'
    },
    { name: 'Department', selector: row => row.departmentName, wrap: true, width: '150px' },
    { name: 'Campus', selector: row => row.locationName, wrap: true, width: '150px' },
    { name: 'Created By', selector: row => row.createdByName, wrap: true, width: '130px' },
  ];

  const filteredData = tasks.filter(t => {
    if (overdueFilter) return isOverdue(t);
    return true;
  });

  return (
    <Card className="mb-4">
      <Card.Header>
        <Card.Title>Department Tasks</Card.Title>
      </Card.Header>
      <Card.Body>
        <DataTable
          columns={columns}
          data={filteredData}
          pagination
          highlightOnHover
          pointerOnHover
          progressPending={loading}
        />
      </Card.Body>
    </Card>
  );
};

export default DepartmentTaskTable;
